import React from 'react'
import Link from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import PageHero from '../components/pageHero'
import Blockquote from '../components/sections/blockquote'
import ContentCenter from '../components/sections/contentCenter'

const Consultation = () => (
	<Layout>
		<Seo title='Консультации по работе с вашим сайтом' />
		<PageHero
			title='Получи консультацию'
			description='Отвечу на твои вопросы, поделюсь своим опытом. Чем смогу помогу, что знаю расскажу или просто поболтаем. Минимальная сессия 30 минут. Если хочешь за это заплатить, то отправляй заявку.'
			button='Заявка на консультацию'
			link='https://forms.gle/KEkzSR4d453heCED8'
			image='/images/raccoon-001-1920.jpg'
		/>
		<Blockquote
			content='Здесь я рассказываю о том как проходит консультация, какие есть возможности, в чем я разбираюсь, чего ждать и чего не ждать от консультации☻'
		/>
		<ContentCenter
			subtitle='Коллективный разум'
			title='Как проходит консультация'
			intro='Здесь интро, которое мы напишем позже... возможно...'
		>
			{/* <p>Весь алгоритм и что вообще будет происходить, я постепенно напишу, не сомневайся. А пока просто заказывай консультацию и узнаешь все на практике ;).</p> */}

			<p>В данный момент я не консультирую. Во-первых большая занятость по другим проектам, во-вторых никто ни разу не осмелился заказать ☻. Если вдруг в тебе есть такая решимость, то пиши через чат или оставляй заявку жмякнув по кнопке, если смогу - помогу.</p>
		</ContentCenter>
	</Layout>
)

export default Consultation